import { React, useState } from "react";
import styled from 'styled-components';

import Appbar from 'components/Appbar.js';

/* this works best for iPhone 6 or higher media query (375 x 667) */

const PageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-image: url(${process.env.PUBLIC_URL + "/images/speaking-bg.jpg"});
  background-position: center top;
  background-size: auto 100%;
  background-attachment: fixed;
`;

const FlexVertCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 56px);
`;

const TextContent = styled.div`
  // margin-top: calc(0.28 * (100vh - 32px));
  @media screen and (min-width: 1000px) {
    font-size: 19px;
    padding: 0 32px;
    max-height: calc(100vh - 88px);
  }
  @media screen and (max-width: 999px) {
    font-size: 15px;
    padding: 0 16px;
    max-height: calc(100vh - 56px);
  }
  background-color: #002060;
  max-width: 640px;

  overflow-y: auto;
`;

const Section = styled.div`
  margin: 14px 0;
`;

const TitleSpan = styled.span`
  font-size: 28px;
  font-weight: 800;
`;

const ClickTitle = styled.div`
  font-size: 20px;
  font-weight: 800;
  line-height: 1.2;
  color: #3295ff;
  cursor: pointer;
  &:hover,
  &:active:hover {
    color: #7fbdff;
  }
  margin-bottom: 12px;
`;

const Para = styled.p`
  margin-bottom: 12px;
`;

const HardRule = styled.hr`
  border-top: 1px solid #0040A0;
  margin: 0 16px;
`;

const Checklist = styled.ul`
  li::marker {
    content: '✓';
  }
  li {
    padding-left: 8px;
  }
`;

const Speaking = ({sidebarDocked, sidebarButtonClicked}) => {
  const [selected, setSelected] = useState(0);
  return (
    <PageContainer>
      <Appbar title="Speaking" sidebarDocked={sidebarDocked} sidebarButtonClicked={sidebarButtonClicked}/>
      <FlexVertCenter>

        <TextContent>

          <Section><TitleSpan>KEYNOTES</TitleSpan> <i>(Tailored to your audience)</i><br/></Section>


          <Section>

            <ClickTitle onClick={(e) => setSelected(1)}>
              Kickstart Your Day with The Conference Connector: For Professional and Personal Development
            </ClickTitle>

            { selected === 1 &&
              <div>

              <Para>
                Highly interactive, motivational, and inspirational! Perfect for conference openings and
                personal/professional development.
              </Para>

              <Para>
                Most attendees come to conferences to learn more about their industry and
                grow their network connections. Often there is time specifically designated
                on conference agendas to network. However, most people aren’t
                networking effectively.
              </Para>

              <Para>
                Kymberli is usually brought in at the beginning of a conference to inspire
                connection throughout the event. She frequently hears that she
                transformed someone’s understanding of what networking is and their new
                approach is still working years later.
              </Para>

              <Para><b>Key Takeaways:</b></Para>
              <Para>Attendees will learn</Para>

              <Checklist>
                <li>How to form <i>“ideal”</i> relationships</li>
                <li>3 foundational principles for authentic networking</li>
                <li>How to meet more people with ease</li>
              </Checklist>

              </div>
            }
          </Section>

          <HardRule/>


          <Section>

            <ClickTitle onClick={(e) => setSelected(2)}>
            Momentum Building Leadership: Create a Perpetually Productive Environment
            </ClickTitle>

            { selected === 2 &&
              <div>

              <Para>
                High-impact leaders understand how to get the best out of their people.
                They have learned how to transcend beyond simply being an effective manager to a transformational leader.
              </Para>

              <Para>
                In addition to serving in several leadership roles, Kymberli has worked with some of the best executives
                in our U.S. military and recognized they demonstrate similar leadership traits. She travels coast to coast,
                sharing these nuggets with corporate audiences.
              </Para>

              <Para><b>Key Takeaways:</b></Para>
              <Para><b>Learn</b></Para>

              <Checklist>
                <li>Fundamentals to communicate and build relationships that apply across generations</li>
                <li>The Social Science Equation for Building Momentum&trade;</li>
                <li>How to build trust and increase resilience that leads to increased innovation, productivity and your bottom line</li>
              </Checklist>

              </div>
            }
          </Section>

          <HardRule/>


          <Section>

            <ClickTitle onClick={(e) => setSelected(3)}>
              It’s an Inside Job: Networking for Internal Engagement
            </ClickTitle>

            { selected === 3 &&
              <div>

              <Para>
                Networking for Internal Engagement, Employee Retention, and Building Trust.
                Leaders and team members learn and experience high level teaming behavior through advanced
                networking concepts that focus on unique talent appreciation and collective trust.
              </Para>

              <Para><b>Key Takeaways:</b></Para>

              <Checklist>
                <li>How to build authentic relationships</li>
                <li>The 3 keys to building solid, mutually beneficial relationships</li>
                <li>Fundamentals of cohesive team-building that leads to higher-level performance and employee retention</li>
              </Checklist>

              </div>
            }
          </Section>

          <HardRule/>


          <Section>

            <ClickTitle onClick={(e) => setSelected(4)}>
              Building Momentum for Upward Mobility: Become More Known, Liked, and Trusted
            </ClickTitle>

            { selected === 4 &&
              <div>

              <Para>
                People hire, promote, and team better with people they know, like, and trust, but first, you must become known!
                Do you know anyone who wants to limit their opportunities? I bet the answer is no. But that is just what someone
                is doing when they don’t advocate for themselves.
              </Para>

              <Para><b>Key Takeaways:</b></Para>
              <Para><b>Learn</b></Para>

              <Checklist>
                <li>How to Say No, Say Yes</li>
                <li>Build Ideal Relationships that are transformational, not transactional</li>
                <li>How to Be the CEO of You and the Leadership Mindset</li>
              </Checklist>

              </div>
            }
          </Section>

          <br/>

        </TextContent>

      </FlexVertCenter>
    </PageContainer>
  );
}

export default Speaking;
